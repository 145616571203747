<template>
  <div :class="{ 'content-shifted': !isSidebarCollapsed }">
    <HeaderProfil />
    <SiderProfile
      @sidebar-toggled="toggleSidebarWidth"
      :is-collapsed="isSidebarCollapsed"
    />
    <div class="content-container">
      <router-view></router-view>
    </div>
    <FooterPage />
  </div>
</template>

<script>
import FooterPage from "@/layoutVisiteur/FooterPage.vue";
import HeaderProfil from "./HeaderProfil.vue";
import SiderProfile from "./SiderProfile.vue";

export default {
  components: {
    HeaderProfil,
    SiderProfile,
    FooterPage,
  },
  data() {
    return {
      isSidebarCollapsed: false,
    };
  },
  methods: {
    toggleSidebarWidth(isCollapsed) {
      this.isSidebarCollapsed = isCollapsed;
    },
  },
};
</script>

<style scoped>
.content-container {
  transition: margin-left 0.5s;
  margin-left: 150px;
}

.content-shifted {
  margin-left: 10px;
}
</style>
