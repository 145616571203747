<template>
  <div :class="[{ 'home-background': isHome }]" id="app">
    <headerBrand v-if="!isLoginPage" />
    <headerPrincipal v-if="!isLoginPage" />
    <div :class="{ 'content-container': !isHome }">
      <router-view></router-view>
    </div>
    <!-- <FooterPage v-if="!isLoginPage" /> -->
  </div>
</template>

<script>
import headerBrand from "./headerBrand";
import headerPrincipal from "./headerPrincipal";
// import FooterPage from "./FooterPage";

export default {
  components: {
    headerBrand,
    headerPrincipal,
    // FooterPage,
  },

  computed: {
    isLoginPage() {
      return this.$route.path === "/login";
    },
    isHome() {
      return this.$route.path === "/";
    },
  },
};
</script>

<style scoped>
.content-container {
  background: linear-gradient(to right, #f0f4f8, #d6d6d6, #fafbfb);
}
.home-background {
  height: 100vh;
  width: 100wh;
  background-image: url("../assets/background-ups.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
