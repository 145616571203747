import { createRouter, createWebHistory } from "vue-router";
import VisitorLayout from "@/layoutVisiteur/VisitorLayout.vue";
import ClientLayout from "@/layoutClient/ClientLayout.vue";
import Home from "@/components/visiteur-components/Home.vue";
import Brand from "@/components/visiteur-components/Brand.vue";
import Panier from "@/components/visiteur-components/Panier.vue";
import LoginPage from "@/components/utilisateur-components/LoginPage.vue";
import ProfilPage from "@/components/utilisateur-components/ProfilPage.vue";

const routes = [
  {
    path: "/",
    component: VisitorLayout,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "brand/:id",
        name: "Brand",
        component: Brand,
      },
      {
        path: "panier",
        name: "Panier",
        component: Panier,
      },
      {
        path: "login",
        name: "LoginPage",
        component: LoginPage,
      },
    ],
  },
  {
    path: "/client",
    component: ClientLayout,
    children: [
      {
        path: "profil",
        name: "ProfilPage",
        component: ProfilPage,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
