<template>

    <div>
        <p>content</p>

    </div>
</template>
<script>
export default {
    name: 'ProfilPage',


};
</script>