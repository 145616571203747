<template>
  <div class="slogan-container">
    <h1 class="slogan-text">UNIVERSAL POWER SPECTRUM</h1>
    <div class="button-container">
      <button class="btn btn-lg btn-white-bg btn-square">UPS inligne</button>
      <button class="btn btn-lg btn-white-bg btn-square">UPS onligne</button>
    </div>
    <!-- <div class="card-text-capture">
      Découvrez les solutions d'infrastructure informatique, les onduleurs
      solaires de pointe et nos services complémentaires.
    </div> -->
    <div class="card card-capture">
      <Categorie />
    </div>
  </div>
</template>

<script>
import Categorie from "./Categorie.vue";

export default {
  components: { Categorie },
  name: "HomePage",
  data() {
    return {
      marqueSelectionnee: "",
    };
  },
};
</script>

<style scoped>
.description-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: large;
  font-style: oblique;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  flex: 1;
  padding: 0 20px;
}

.content-container {
  display: flex;
}

.slogan-container {
  margin-top: 100px;
  text-align: center;
  color: white;
  margin-bottom: 2.5rem;
}

.button-container {
  margin-top: 1rem;
}

.card.card-capture {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
}

.card-text-capture {
  color: white;
  font-style: oblique;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 22px;
  background-color: #576c30;
  width: 100%;
  height: 10%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.slogan-text {
  font-size: 2rem;
  color: white;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(0, 0, 0, 0.5),
    0 0 15px rgba(0, 0, 0, 0.5);
}

.button-container {
  margin-top: 7%;
  display: flex;
  justify-content: center;
}

.button-container1 {
  margin-top: 20%;
  display: flex;
  justify-content: center;
}

.button-container1 .btn {
  margin: 0 5%;
}

.btn-white-bg {
  background: linear-gradient(to right, #f0f4f8, #d6d6d6, #fafbfb);
  color: #576c30;
  border: 1px solid #ccc;
  font-family: Georgia, "Times New Roman", Times, serif;
  width: 400px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: background-color 0.6s ease, color 0.6s ease, transform 0.6s ease;
}

.button-container .btn {
  margin: 0 5%;
}

.btn-square {
  border-radius: 0;
}

.btn-white-bg:hover {
  background: linear-gradient(to right, #576c30, #576c30);

  color: #f6f0f0;
}

.image-container {
  display: flex;
  flex: 1;
}

.image-wrapper {
  flex: 1;
  position: relative;
  max-width: 50%;
}

.image-wrapper1 {
  flex: 1;
  position: relative;
  max-width: 50%;
}

.image-wrapper img {
  width: 100%;
  height: auto;
}

.image-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  color: #333;
  text-align: center;
  padding: 10px;
  font-size: 1.5rem;
  text-decoration: underline;
  bottom: 30px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.image-overlay:hover {
  background-color: #576c30;
  color: #fff;
}

.image-wrapper:first-child {
  border-right: 1px solid #ccc;
}

.image-wrapper:last-child {
  border-left: 1px solid #ccc;
}

.image-wrapper1 {
  flex: 1;
  position: relative;
  margin: 10px;
  border: 2px solid #000;
  box-sizing: border-box;
  overflow: hidden;
}

.image-card {
  border: 0;
  margin-top: 10px;
}

.image-card1 {
  border-radius: 0;

  border: 0;
}

.image-wrapper1 img {
  width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.image-overlay1 {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 1.5rem;
  text-decoration: underline;
  background-color: #576c30;
}

.carousel-icon {
  background-color: #000;
}

.card-deck {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.1);
}

.small-card {
  cursor: pointer;
  width: 100%;
  width: 28rem;
  flex: 1;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  border-radius: 0%;
}

.small-card:hover {
  transform: translateY(-7px);
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.1);
}

.card-img-top {
  margin: 0 auto;
  display: block;
  max-width: 100%;
  height: 300px;
  object-fit: cover;
}

.card-title {
  text-align: center;
  margin-bottom: 10px;
}

.card-text {
  margin-bottom: 0;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.card-icon {
  width: 50px;
  height: auto;
  display: block;
}

.title-with-underline {
  text-decoration: underline;
}

.card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-capture {
  border: 0;
  border-radius: 0;
  margin: 20px;

  background-color: transparent !important;
  box-shadow: -6px 8px 16px rgba(0, 0, 0, 0.2) !important;
}

.card-icon {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.card-text1 {
  margin-bottom: 20px;
  font-style: oblique;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 18px;
}

.card-title1 {
  font-style: oblique;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

.card-title {
  font-size: 14px;
  margin-top: 10px;
}

.card-carousel {
  border-radius: 0%;
}
.card-icon {
  transition: transform 0.3s, opacity 0.3s;
}

.card-icon:hover {
  transform: scale(1.1); /* Effet de zoom */
  opacity: 0.8; /* Changer l'opacité */
}
</style>
<style scoped>
@media (max-width: 576px) {
  .slogan-container {
    margin-top: 7px;

    margin-bottom: 1.5rem;
  }

  .button-container {
    margin-top: 0.6rem;
  }

  .card.card-capture {
    padding: 0.6rem;
  }

  .card-text-capture {
    font-size: 14px;
    width: 100%;
    height: 10%;
  }
  .slogan-text {
    font-size: 1.5rem;
  }

  .button-container {
    margin-top: 7%;
  }

  .btn-white-bg {
    width: 220px;
    height: 30px;
    font-size: 1rem;
  }

  .card-text-capture {
    font-size: 14px;
    width: 100%;
    height: 10%;
    font-family: Arial, Helvetica, sans-serif;
  }
  .card.card-capture {
    padding: 0.7rem;
  }
}
</style>
<style scoped>
@media (min-width: 577px) and (max-width: 868px) {
  .slogan-container {
    margin-top: 10px;

    margin-bottom: 2rem;
  }

  .button-container {
    margin-top: 0.8rem;
  }

  .card.card-capture {
    padding: 0.8rem;
  }

  .card-text-capture {
    font-size: 18px;
    width: 100%;
    height: 10%;
  }
  .slogan-text {
    font-size: 1.8rem;
  }

  .button-container {
    margin-top: 10%;
  }

  .btn-white-bg {
    width: 280px;
    height: 40px;
    font-size: 1.2rem;
  }

  .card-text-capture {
    font-size: 18px;
    width: 100%;
    height: 10%;
    font-family: Arial, Helvetica, sans-serif;
  }
  .card.card-capture {
    padding: 0.7rem;
  }
}
</style>
<style scoped>
@media (min-width: 1540px) {
  .slogan-container {
    margin-top: 20px;

    margin-bottom: 2.5rem;
  }

  .button-container {
    margin-top: 1rem;
  }

  .card.card-capture {
    padding: 1rem;
  }

  .card-text-capture {
    font-size: 24px;
  }
  .slogan-text {
    font-size: 2.2rem;
  }

  .btn-white-bg {
    width: 500px;
    height: 60px;
    font-size: 1.2rem;
  }

  .card-text-capture {
    font-size: 24px;
  }
  .card.card-capture {
    padding: 1.2rem;
  }
}
</style>
