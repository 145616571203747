import { createApp } from "vue";
import App from "./App.vue";
import router from "./routes/Index";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "primeicons/primeicons.css";
import "ant-design-vue/dist/reset.css";
import Antd from "ant-design-vue";
import "bootstrap";

const app = createApp(App);
// main.js or router/index.js
router.beforeEach((to, from, next) => {
  if (to.name === "/") {
    document.body.classList.add("home-background");
  } else {
    document.body.classList.remove("home-background");
  }
  next();
});

app.use(router);
app.use(Antd);
app.mount("#app");
