<template>
    <div class="sidebar" :style="{ width: sidebarWidth + 'px' }">
        <div class="sidebar-header" @click="toggleSidebarWidth">
            <img src="../assets/UPSw.png" alt="Logo">
        </div>
        <div class="sidebar-menu">
            <div class="sidebar-section">
                <router-link to="/client/profil" class="sidebar-link">
                    <i class="bi bi-person icon-side"></i>
                    <transition name="fade">
                        <span v-if="!isCollapsed" class="sidebar-text">Profil</span>
                    </transition>
                </router-link>
            </div>
            <div class="sidebar-section">
                <router-link to="/orders" class="sidebar-link">
                    <i class="bi bi-clipboard2-check icon-side"></i>
                    <transition name="fade">
                        <span v-if="!isCollapsed" class="sidebar-text">Commandes</span>
                    </transition>
                </router-link>
            </div>
            <div class="sidebar-section">
                <router-link to="/cart" class="sidebar-link">
                    <i class="bi bi-cart-check icon-side"></i>
                    <transition name="fade">
                        <span v-if="!isCollapsed" class="sidebar-text">Panier</span>
                    </transition>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isCollapsed: Boolean,
    },
    data() {
        return {
            sidebarWidth: 160,
            sidebarCollapsedWidth: 65,
            isCollapsedInternal: false,
        };
    },
    methods: {
        toggleSidebarWidth() {
            this.isCollapsedInternal = !this.isCollapsedInternal;
            this.sidebarWidth = this.isCollapsedInternal ? this.sidebarCollapsedWidth : 160;
            this.$emit('sidebar-toggled', this.isCollapsedInternal);
        },
        updateSidebarWidth() {
            if (window.innerWidth <= 1100) {
                this.sidebarWidth = 65;
            } else {
                this.sidebarWidth = 160;
            }
        },
    },
    mounted() {
        this.updateSidebarWidth();
        window.addEventListener('resize', this.updateSidebarWidth);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateSidebarWidth);
    },
};
</script>

<style scoped>
.sidebar {
    background-color: #576c30;
    color: #fff;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 7px;
    transition: width 0.5s;
    cursor: pointer;
}

.sidebar-header {
    text-align: center;
    padding: 10px 0;
}

.sidebar-header img {
    max-width: 40px;
}

.sidebar-section {
    display: flex;
    align-items: center;
    padding: 10px;
    text-align: center;
}

.sidebar-link {
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
}

.sidebar-link i {
    margin-right: 10px;
}

.icon-side {
    font-size: 20px;
}

.sidebar-menu {
    margin-left: 10px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
