<template>
  <footer class="footer mt-auto py-3 text-white border-top">
    <div class="container">
      <div class="row">
        <div class="col-md-4 border-right">
          <h5>À propos</h5>
          <p class="p-footer">
            Ce site est dédié à fournir des informations <br />sur nos produits
            et services.
          </p>
        </div>
        <div class="col-md-4 border-right">
          <h5>Liens utiles</h5>
          <ul class="list-unstyled">
            <li><router-link to="/">Accueil</router-link></li>
            <li><router-link to="/produits">Produits</router-link></li>
            <li><router-link to="/contact">Contactez-nous</router-link></li>
          </ul>
        </div>
        <div class="col-md-4">
          <h5>Contact</h5>
          <address>
            <strong>Adresse :</strong><br />
            02 rue Mahmoud Ghaznaoui<br />
            Menzha 4, Tunis 1082<br />
            <abbr title="Téléphone">Tél :</abbr> +216 71 34 30 76
          </address>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-center">
          <p class="text-muted">&copy; 2024 Next. Tous droits réservés.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterPage",
};
</script>

<style scoped>
.footer {
  background-color: #a2a5a7;
  color: #fff;
  border-top: 1px solid #928a8a;
  padding-top: 10px;
}

.border-right {
  border-right: 1px solid #ddd;
}

.footer .container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.footer .row {
  margin: 0;
}

.p-footer {
  margin-left: 10px;
}
</style>
