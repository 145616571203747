<template>
  <div class="login-page-container">
    <div class="auth-card">
      <div class="position-relative image-side">
        <p class="class-h2">LA MAGIE EST DANS LES DÉTAILS</p>
        <p class="text-white mb-0" style="font-size: 14px;">
          Veuillez utiliser vos identifiants pour vous connecter.
          <br />Si vous n'êtes pas membre, veuillez
          <router-link to="/user/register" class="custom-link-color"> vous inscrire</router-link>.
        </p>
      </div>
      <div class="form-side">
        <router-link to="/" class="active">
          <span class="logo-single"></span>
        </router-link>
        <h6 class="mb-4">Connexion</h6>
        <form @submit.prevent="login">

          <fieldset class="form-group has-float-label mb-4">
            <legend class="bv-no-focus-ring col-form-label pt-0">E-mail</legend>
            <div>
              <input v-model="email" type="text" class="form-control" />
            </div>
          </fieldset>
          <fieldset class="form-group has-float-label mb-4">
            <legend class="bv-no-focus-ring col-form-label pt-0">Mot de passe</legend>
            <div>
              <input v-model="password" type="password" class="form-control" />
            </div>
          </fieldset>
          <div class="form-check mb-4">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
            <label class="form-check-label" for="flexCheckChecked">
              Se souvenir de moi
            </label>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <router-link to="/user/forgot-password">Mot de passe oublié ?</router-link>
            <button type="submit" class="btn btn-primary btn-lg btn-multiple-state btn-shadow login-btn"
              :disabled="loading">
              <span v-if="loading" class="spinner d-inline-block">
                <span class="bounce1"></span>
                <span class="bounce2"></span>
                <span class="bounce3"></span>
              </span>
              <span v-if="loginSuccess" class="icon success"><i class="simple-icon-check"></i></span>
              <span v-if="loginError" class="icon fail"><i class="simple-icon-exclamation"></i></span>
              <span class="label">CONNEXION</span>
            </button>
          </div>
        </form>
        <div v-if="loginSuccess" class="alert alert-success mt-3" role="alert">
          Connexion réussie ! Bienvenue, {{ user.name }}. Redirection...
        </div>
        <div v-if="loginError" class="alert alert-danger mt-3" role="alert">
          Échec de la connexion. Veuillez vérifier vos identifiants et réessayer.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/axios/axios";

export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      loginSuccess: false,
      loginError: false,
      user: null,
    };
  },
  methods: {
    async login() {
      this.loading = true;
      this.loginError = false;
      this.loginSuccess = false;

      try {
        const response = await axiosInstance.post("/auth/local", {
          identifier: this.email,
          password: this.password,
        });

        const token = response.data.jwt;
        const user = response.data.user;

        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));

        this.user = user;
        this.loginSuccess = true;

        this.$router.push({ name: "ProfilPage" });
      } catch (error) {
        console.error("Login error:", error.response.data);
        this.loginError = true;
      } finally {
        this.loading = false;
      }
    },

  },
  mounted() {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      this.user = JSON.parse(storedUser);
    }
  },
};
</script>

<style scoped>
.login-page-container {
  min-height: 100vh;
  background-image: url("../../assets/balloon-lg.jpg");

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-card {
  background-color: white;
  border-radius: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1),
    0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  height: auto;
  max-width: 1000px;
}



.auth-card .image-side {
  width: 40%;
  background: url("../../assets/balloon.jpg") no-repeat center;
  background-size: cover;
  padding: 80px 40px;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  text-align: left;
}

.auth-card .form-side {
  width: 60%;
  padding: 80px;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
  text-align: left;
}


.auth-card .image-side .h3 {
  line-height: 0.8rem;
}

.login-btn {
  background-color: #00365a;
  border-color: #00365a;
  border-radius: 50px;
  padding: 0.3rem 2rem;
  font-size: 0.85rem;
}

.login-btn:hover,
.login-btn:focus {
  background-color: #002d4d;
  border-color: #002d4d;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 2px rgba(0, 0, 0, 0.15) !important;
}

.form-side {
  position: relative;
  padding-left: 1rem;
}

.logo-single {
  width: 110px;
  height: 60px;
  background: url('../../assets/logo.png') no-repeat;
  background-position: 50%;
  display: inline-block;
  margin-bottom: 20px;
  background-size: contain;
}



.has-float-label {
  position: relative;
  margin-bottom: 1.5rem;
}

.has-float-label label {
  border-bottom: none !important;
}

.has-float-label>input {
  width: 100%;
  padding: 1rem;
  font-size: 16px;
  border: 1px solid #ccc;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control {
  --bs-border-radius: 0%;

}



.has-float-label>span:last-of-type,
.has-float-label label,
.has-float-label legend {
  position: absolute;
  cursor: text;
  font-size: 90%;
  opacity: 1;
  top: -0.6em;
  left: 1.5rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
}

.has-float-label>input:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.class-h2 {
  color: white;
  font-weight: bold;
  font-family: Georgia, 'Times New Roman', Times, serif;

}

.custom-link-color {
  color: #576c30;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;

}

.form-check {
  justify-content: flex-start;

}
</style>
