<template>
  <nav class="navbar navbar-expand-lg navbar-light custom-navbar">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div v-for="brandItem in brand" :key="brandItem.id" class="logo-item">
        <a
          :href="'/brand/' + brandItem.id"
          class="nav-link"
          :class="{
            'router-link-active': $route.params.id === brandItem.id.toString(),
          }"
          @click.prevent="openInNewWindow('/brand/' + brandItem.id)"
        >
          <img
            :src="formatImageUrl(brandItem.attributes.logo.data.attributes.url)"
            class="brand-logo"
            :alt="brandItem.attributes.name"
          />
        </a>
      </div>
    </div>

    <div class="nav-icons">
      <router-link to="/login" class="login-link">
        <span class="pi pi-user user-icon"></span>
        <span class="login-user">Login</span>
      </router-link>

      <a-popover placement="bottom">
        <template #content>
          <div>
            <router-link to="/panier">
              <a-button type="text">Visiter Panier</a-button>
            </router-link>
          </div>
          <div>
            <a-button
              danger
              type="text"
              @click="LogiquePanier()"
              :disabled="count === 0"
            >
              Vider Panier
            </a-button>
          </div>
        </template>

        <a href="#" class="panier-container" style="margin-right: 20px">
          <i class="pi pi-cart-arrow-down panier-icon"></i>
          <span class="badge">{{ count }}</span>
        </a>
      </a-popover>
    </div>
  </nav>
</template>

<script>
import axiosInstance from "@/axios/axios";
import { message } from "ant-design-vue";
import { EventBus } from "@/eventBus";

export default {
  name: "HeaderBrand",
  data() {
    return {
      brand: [],
      count: 0,
    };
  },
  created() {
    this.fetchBrand();
    this.loadCart();

    // Écouter l'événement de mise à jour du panier
    EventBus.on("update-cart", (newCount) => {
      this.count = newCount;
    });
  },
  methods: {
    openInNewWindow(url) {
      window.open(url, "_blank");
    },

    async fetchBrand() {
      try {
        const response = await axiosInstance.get("/brands?populate=*");
        if (response.data && response.data.data) {
          this.brand = response.data.data;
          console.log(this.brand);
        } else {
          console.error("Réponse vide lors de la récupération des marques");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des marques:", error);
        throw error;
      }
    },
    formatImageUrl(url) {
      if (url.startsWith("/")) {
        return `http://localhost:1337${url}`;
      } else {
        return "https://static.vecteezy.com/ti/vecteur-libre/p3/4141669-aucune-photo-ou-image-blanche-icone-chargement-images-ou-image-manquante-marque-image-non-disponible-ou-image-coming-soon-sign-simple-nature-silhouette-in-frame-illustrationle-isolee-vectoriel.jpg";
      }
    },
    capitalize(str) {
      if (typeof str !== "string" || str.length === 0) return "";
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    loadCart() {
      const savedPanier = localStorage.getItem("panier");
      if (savedPanier) {
        this.panier = JSON.parse(savedPanier);
        this.count = this.panier.length;
      }
    },
    viderPanier() {
      this.panier = [];
      this.count = 0;
      localStorage.removeItem("panier");
      localStorage.removeItem("count");

      // Émettre un événement pour mettre à jour le panier
      EventBus.emit("update-cart", this.count);
    },
    showMessagePanier() {
      message.warn("Panier vide!");
    },
    LogiquePanier() {
      this.showMessagePanier();
      this.viderPanier();
    },
  },
};
</script>

<style scoped>
.custom-navbar {
  height: 45px;
  display: flex;
  align-items: center;
  margin-left: auto;
  background: linear-gradient(to bottom, #ffff, #f4f7f7, #d6d6d6);
  justify-content: space-between;
  font-family: Helvetica, Arial, sans-serif;
  overflow: hidden;
  position: relative;
}

.logo-item {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  transition: background-color 0.3s ease;
  overflow: hidden;
}

.logo-item:hover {
  background-color: rgba(220, 220, 220, 0.8);
}

.brand-logo {
  margin-left: 10px;
  margin-right: 10px;
  width: 100px;
  height: auto;
}

.navbar-nav {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
}

.navbar-nav li {
  margin: 0;
}

.router-link-active {
  color: rgba(4, 10, 20, 0.8) !important;
}

.navbar-logo {
  margin-left: 50px;
  margin-right: 10px;
  height: 27px;
}

.user-icon {
  color: #576c30;
  margin-right: 5px;
}

.login-user {
  color: #576c30;
  margin-right: 20px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.panier-icon {
  font-size: 18px;
  color: #576c30;
  margin-top: 8px;
}

.nav-icons {
  display: flex;
  align-items: center;
  margin-right: 100px;
}

.panier-container {
  position: relative;
  display: inline-block;
}

.badge {
  position: absolute;
  top: -3px;
  margin-left: 1px;
  background-color: red;
  color: white;
  border-radius: 30%;
  padding: 2px 6px;
  font-size: 12px;
}

.login-link {
  text-decoration: none;
}
</style>
