<template>
  <div class="header-container">
    <nav
      class="navbar navbar-expand-lg bg-body-tertiary navbar-shadow header-container"
    >
      <div class="container-fluid">
        <img
          src="@/assets/UPSw.png"
          alt="Logo"
          class="navbar-logo"
          id="navbar-logo"
        />

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarScroll">
          <ul
            class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll"
            style="--bs-scroll-height: 100px"
          >
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/"
                >Accueil</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#"
                >À propos</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#"
                >Produits</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#"
                >Services</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#"
                >Contact</a
              >
            </li>
          </ul>
          <form class="d-flex" role="search">
            <div class="input-group search-input-group">
              <input
                class="form-control transparent-input"
                type="search"
                placeholder="Recherche..."
                aria-label="Search"
              />
              <span class="input-group-text">
                <i class="bi bi-search"></i>
              </span>
            </div>
          </form>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import axiosInstance from "@/axios/axios";

export default {
  name: "headerPrincipal",
  data() {
    return {
      marqueSelectionnee: "",
    };
  },
  created() {
    this.fetchBrandDetails();
  },
  watch: {
    "$route.params.id"() {
      this.fetchBrandDetails();
    },
  },
  methods: {
    async fetchBrandDetails() {
      const brandId = this.$route.params.id;
      try {
        if (brandId) {
          const response = await axiosInstance.get(`/brands/${brandId}`);
          const data = response.data;
          if (
            data &&
            data.data &&
            data.data.attributes &&
            data.data.attributes.name
          ) {
            this.marqueSelectionnee = data.data.attributes.name;
          } else {
            this.marqueSelectionnee = "";
          }
        } else {
          this.marqueSelectionnee = "";
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails de la marque :",
          error
        );
        this.marqueSelectionnee = "";
      }
    },
  },
};
</script>

<style scoped>
.header-container {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: transparent;
  background: linear-gradient(
    rgba(0, 0, 0, 0.38) 0%,
    rgba(0, 0, 0, 0.28) 70.83%,
    rgba(0, 0, 0, 0.12) 87.5%,
    rgba(0, 0, 0, 0) 100%
  );
  transition: background 0.5s ease, background-color 0.5s ease;
  z-index: 400;
}

.header-container:hover {
  background: #f9faf7 !important;
}

.header-container:hover .navbar .navbar-nav .nav-link {
  color: #576c30;
}

.navbar {
  background-color: transparent !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.navbar .navbar-nav .nav-link {
  color: white;
  transition: color 0.7s ease;
}

.navbar .navbar-nav .nav-link:hover {
  text-decoration: underline;
}

.search-wrapper {
  position: relative;
  width: 100%;
}

.input-search {
  background: transparent;
  color: rgb(0, 0, 0);
  appearance: none;
  border: none;
  border-radius: 0;
  line-height: 1.2;
  padding: 0.2rem 0.5rem;
  outline: none;
  width: calc(100% - 2rem);
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  height: 1.5rem;
}

.input-search::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.navbar-shadow {
  box-shadow: 0 3px 5px rgba(150, 145, 145, 0.1);
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-nav {
  display: flex;
  justify-content: center;
  width: 100%;
}

.navbar-nav li {
  padding: 0 10px;
}

.navbar-nav .nav-link {
  padding: 0.5rem 1rem;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.navbar-nav .nav-item:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  width: 100%;
  text-align: left;
}

.dropdown-menu a {
  display: block;
  padding: 0.5rem 1rem;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}

.custom-dropdown-toggle {
  background-color: transparent;
  border: none;
  outline: none;
  color: inherit;
  padding: 0;
  cursor: pointer;
}

.custom-dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu {
  margin-top: 0;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.brand-title {
  margin-left: 10px;
}

.transparent-input {
  background-color: transparent;
  border: none;

  color: #576c30;
  border-radius: 0;
}

.transparent-input::placeholder {
  color: #576c30;
}

.transparent-input:focus {
  box-shadow: none;
  outline: none;
  background-color: transparent;
}

.input-group-text {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #576c30;
}

.input-group-text i {
  color: #576c30;
}

.search-input-group {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #576c30;
}

.navbar-logo {
  margin-left: 50px;
  margin-right: 10px;
  height: 40px;
  content: url("@/assets/UPSw.png");

  transition: content 0.3s ease;
}

.header-container:hover .navbar-logo {
  content: url("@/assets/logo.png");
}
</style>
