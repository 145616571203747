<template>
    <div class="panier-page-container">
        <div class="gauche">
            <div v-for="(product, index) in panier" :key="product.id" class="card card-gauche">
                <div class="row g-0">
                    <div class="col-md-4">
                        <img :src="getFirstImage(product)" class="card-img-top" :alt="product.attributes.name">

                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <h5 class="card-title">{{ product.attributes.name }}</h5>
                            <p class="card-text">{{ product.description }}</p>
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="input-group mb-3">
                                    <input type="number" class="form-control input-quantity" aria-label="Quantity"
                                        aria-describedby="button-addon2" v-model.number="quantities[index]" :min="1"
                                        :max="25">
                                    <div class="btn-group-vertical">
                                        <button class="btn btn-outline-secondary btn-sm btn-left-rounded" type="button"
                                            id="button-addon-plus" @click="incrementQuantity(index)">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                        <button class="btn btn-outline-secondary btn-sm btn-left-rounded" type="button"
                                            id="button-addon-minus" @click="decrementQuantity(index)">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="class-prix"> Prix: {{ product.attributes.price }}.$</div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="align-button">
                                    <span v-if="product.stock === 0" class="badge text-bg-danger">
                                        Hors stock
                                    </span>
                                    <span v-else class="badge text-bg-success">
                                        En stock
                                    </span>
                                </div>
                                <div class="ml-auto card-actions">
                                    <button class="btn btn-outline-danger btn-sm" @click="retirerProduit(product)"
                                        data-bs-toggle="tooltip" data-bs-html="true"
                                        :data-bs-title="'<em>Ajouté au panier</em>'">
                                        <i class=" bi bi-cart-dash icon-action"></i>
                                    </button>
                                </div>
                            </div>
                            <p class="card-text"><small class="text-body-secondary">Last updated 3 mins
                                    ago</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="droite">
            <div class="card card-droite">
                <div class="card-body">
                    <h5 class="card-title">Panier d'Achats
                    </h5>

                    <table class="table table-success table-striped">

                        <tbody>
                            <tr v-for="(item, index) in panier" :key="item.id">
                                <td class="text-start">{{ item.attributes.name }}</td>
                                <td>{{ quantities[index] }}</td>
                                <td>{{ calculateTotal(item, index) }}.$</td>
                            </tr>
                        </tbody>
                    </table>


                    <table class="table table-success table-striped">

                        <tbody>
                            <tr>
                                <td>Total à payer : {{ totalPrix }}.$</td>
                            </tr>
                        </tbody>
                    </table>

                    <div>
                        <button type="button" class="btn  btn-outline-success">Procéder au paiement</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>


<script>
import { message } from 'ant-design-vue';
import { EventBus } from '@/eventBus';

export default {
    name: 'PanierDetail',
    components: {
    },
    data() {
        return {
            products: [
                { id: 1, image: "produit-01.jpg", title: "Produit 1", description: "Description du produit 1", stock: 10, prix: 351 },
                { id: 2, image: "produit-02.jpg", title: "Produit 2", description: "Description du produit 2", stock: 10, prix: 351 },
                { id: 3, image: "produit-03.jpg", title: "Produit 3", description: "Description du produit 3", stock: 10, prix: 351 },
                { id: 4, image: "produit-04.jpg", title: "Produit 4", description: "Description du produit 4", stock: 5, prix: 351 },
                { id: 5, image: "produit-05.jpg", title: "Produit 5", description: "Description du produit 5", stock: 1, prix: 351 },
                { id: 6, image: "produit-06.jpg", title: "Produit 6", description: "Description du produit 6", stock: 0, prix: 351 },
            ],
            panier: [],
            count: 0,
            quantities: [],
            totalPrix: 0

        };
    },
    created() {
        this.quantities = Array(this.products.length).fill(1);
        this.loadCart();
        this.calculerSommeProduits();
        // Écouter l'événement de mise à jour du panier
        EventBus.on('update-cart', (newCount) => {
            this.count = newCount;
        });

    },

    methods: {
        loadCart() {
            const savedPanier = localStorage.getItem('panier');
            if (savedPanier) {
                this.panier = JSON.parse(savedPanier);
            }
            const savedCount = localStorage.getItem('count');
            if (savedCount) {
                this.count = parseInt(savedCount, 10);
            }
        },

        getFirstImage(product) {
            if (product.attributes.images && product.attributes.images.data.length > 0) {
                const imageUrl = product.attributes.images.data[0].attributes.url;
                if (imageUrl.startsWith('/')) {
                    return `http://localhost:1337${imageUrl}`;
                } else {
                    return imageUrl;
                }
            } else {
                return 'https://static.vecteezy.com/ti/vecteur-libre/p3/4141669-aucune-photo-ou-image-blanche-icone-chargement-images-ou-image-manquante-marque-image-non-disponible-ou-image-coming-soon-sign-simple-nature-silhouette-in-frame-illustrationle-isolee-vectoriel.jpg';
            }
        },
        calculerSommeProduits() {
            let total = 0;
            this.panier.forEach((item, index) => {
                total += this.quantities[index] * item.attributes.price;
            });
            this.totalPrix = total.toFixed(2);
        },
        calculateTotal(item, index) {
            return (this.quantities[index] * item.attributes.price).toFixed(2);
        },

        retirerProduit(produit) {
            const index = this.panier.findIndex(p => p.id === produit.id);
            if (index !== -1) {
                this.panier.splice(index, 1);
                localStorage.setItem('panier', JSON.stringify(this.panier));
                this.count--;
                localStorage.setItem('count', this.count);
                message.info('Le produit a été retiré du panier!');
                EventBus.emit('update-cart', this.count);
            }
        },
        viderPanier() {
            this.panier = [];
            this.count = 0;
            localStorage.removeItem('panier');
            localStorage.removeItem('count');

        },
        incrementQuantity(index) {
            if (this.quantities[index] < 25) {
                this.quantities[index]++;
            }
            this.calculerSommeProduits();

        },
        decrementQuantity(index) {
            if (this.quantities[index] > 1) {
                this.quantities[index]--;
            }
            this.calculerSommeProduits();

        }
    }
};
</script>

<style scoped>
.panier-page-container {
    min-height: 100vh;
    display: flex;
    padding: 50px;

}

.gauche {
    flex: 3;
}

.droite {
    flex: 1;
}

.container {
    display: flex;
    flex-wrap: wrap;
}

.input-group {
    display: flex;
    align-items: stretch;
    width: 65px;
    /* Largeur totale du groupe */
}

.input-quantity {
    text-align: center;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;


}

.input-quantity::-webkit-outer-spin-button,
.input-quantity::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.btn-group-vertical {
    display: flex;
    flex-direction: column;
    margin-left: -1px;
}

.btn-group-vertical .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.42rem;
    width: auto;
    border-radius: 0;
}

.btn-left-rounded:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
}

.btn-left-rounded:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 5px;
}

.btn:focus,
.btn:active {
    outline: none;
    /* Supprime le contour de focus */
    box-shadow: none;
    /* Supprime toute ombre portée */
}

.icon-action {
    font-size: large;
}

.class-prix {
    margin-left: 15px;
    margin-bottom: 15px;
}

.card-gauche {
    max-width: 750px;
    margin-bottom: 10px;
    border-radius: 0%;
}

.card-droite {
    border-radius: 0%;
    min-width: 500px;
    min-height: 500px
}
</style>