<template>
    <div class="brand-page-container">
        <div class="container">
            <div class="col-md-4" v-for="product in products" :key="product.id">
                <div class="card mb-4">
                    <img :src="getFirstImage(product)" class="card-img-top" :alt="product.attributes.name">
                    <div class="card-body">
                        <div class="card-title">{{ product.attributes.name }}</div>

                        <div class="d-flex align-items-center">
                            <div class="align-button">
                                <span v-if="product.stock === 0" class="badge text-bg-danger">
                                    Hors stock
                                </span>
                                <span v-else class="badge text-bg-success">
                                    En stock
                                </span>
                            </div>

                            <div class="ml-auto card-actions">
                                <button class="btn btn-outline-primary btn-sm" @click="addToCart(product)"
                                    data-bs-toggle="tooltip" data-bs-html="true"
                                    :data-bs-title="'<em>Ajouté au panier</em>'">
                                    <i class="bi bi-cart-plus icon-action"></i>
                                </button>
                                <button type="button" class="btn btn-outline-secondary btn-sm" data-bs-toggle="tooltip"
                                    data-bs-html="true" data-bs-placement="top"
                                    :data-bs-title="`<em>Voir les détails de ${product.attributes.name}</em>`">
                                    <i class="bi bi-info-circle icon-action"></i>
                                </button>
                            </div>
                        </div>

                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { message } from "ant-design-vue";
import { EventBus } from '@/eventBus';
import 'bootstrap/dist/css/bootstrap.css';
import * as bootstrap from 'bootstrap';
import axiosInstance from "@/axios/axios";

export default {
    name: "BrandPage",
    data() {
        return {
            products: [],
            panier: [],
            count: 0,
        };
    },
    mounted() {
        this.loadCart();
        this.fetchProduitByBrand(this.$route.params.id);
    },
    updated() {
        this.initializeTooltips();
    },
    watch: {
        '$route.params.id': {
            handler(newId) {
                this.fetchProduitByBrand(newId);
            },
            immediate: true,
        },
    },
    methods: {
        async fetchProduitByBrand(id) {
            try {
                const response = await axiosInstance.get(`/brands/${id}?populate[products][populate]=images`);
                if (response.data && response.data.data) {
                    this.brand = response.data.data;
                    this.products = response.data.data.attributes.products.data;
                } else {
                    console.error("Réponse vide lors de la récupération des marques");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des marques:", error);
                throw error;
            }
        },
        addToCart(product) {
            const existingItemIndex = this.panier.findIndex((item) => item.id === product.id);
            if (existingItemIndex !== -1) {
                message.info("Cet élément est déjà dans le panier");
                return;
            }
            this.ajouterAuPanier(product);
            this.showMessage();
            this.increase();
            this.saveCart();
            this.count = this.panier.length;
            EventBus.emit('update-cart', this.count);
        },
        getFirstImage(product) {
            if (product.attributes.images && product.attributes.images.data.length > 0) {
                const imageUrl = product.attributes.images.data[0].attributes.url;
                if (imageUrl.startsWith('/')) {
                    return `http://localhost:1337${imageUrl}`;
                } else {
                    return imageUrl;
                }
            } else {
                return 'https://static.vecteezy.com/ti/vecteur-libre/p3/4141669-aucune-photo-ou-image-blanche-icone-chargement-images-ou-image-manquante-marque-image-non-disponible-ou-image-coming-soon-sign-simple-nature-silhouette-in-frame-illustrationle-isolee-vectoriel.jpg';
            }
        },
        showMessage() {
            message.success("Produit ajouté au panier!");
        },
        ajouterAuPanier(product) {
            this.panier.push(product);
        },
        saveCart() {
            localStorage.setItem("panier", JSON.stringify(this.panier));
            localStorage.setItem("count", this.count.toString());
        },
        loadCart() {
            const savedPanier = localStorage.getItem('panier');
            if (savedPanier) {
                this.panier = JSON.parse(savedPanier);
            }
            const savedCount = localStorage.getItem('count');
            if (savedCount) {
                this.count = parseInt(savedCount, 10);
            }
        },
        increase() {
            this.count++;
        },
        initializeTooltips() {
            const tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            tooltipTriggerList.forEach((tooltipTriggerEl) => {
                new bootstrap.Tooltip(tooltipTriggerEl);
            });
        }
    },
};
</script>

<style scoped>
.brand-page-container {
    padding: 40px;

    min-height: 100vh;
}

.container {
    display: flex;
    flex-wrap: wrap;
}

.card {
    background: linear-gradient(to right, #f0f4f8, #d6d6d6, #fafbfb);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 400px;
    min-width: 400px;

}

.card img {
    height: 300px;
    object-fit: cover;
}

.card-actions {
    display: flex;
    align-items: center;
}

.card-actions button {
    margin-right: 10px;
}

.icon-action {
    font-size: large;
}

.card-title {
    font-size: large;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.btn:focus,
.btn:active {
    outline: none;
    box-shadow: none;
}
</style>