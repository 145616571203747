<template>
  <div class="card-body">
    <div class="cards-container">
      <div
        class="card-container"
        v-for="category in categories"
        :key="category.id"
      >
        <a :href="category.link" class="card-link">
          <div class="card-image">
            <img
              :src="getImagePath(category.image)"
              class="card-icon"
              :alt="category.title"
            />
          </div>
          <div class="card-title">
            <p>{{ category.title }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoriePage",
  data() {
    return {
      categories: [
        { id: 1, image: "PIC-1.png", title: "UPS", link: "#link1" },
        { id: 2, image: "PIC-2.png", title: "AVR", link: "#link2" },
        { id: 3, image: "PIC-3.png", title: "BATTERIES", link: "#link3" },
        { id: 4, image: "PIC-4.png", title: "INVERTERS", link: "#link4" },
        { id: 5, image: "PIC-5.png", title: "ENERGY STORAGE", link: "#link5" },
        { id: 6, image: "PIC-6.png", title: "PSU", link: "#link6" },
        { id: 7, image: "PIC-7.png", title: "CASES", link: "#link7" },
      ],
    };
  },
  methods: {
    getImagePath(image) {
      return require(`../../assets/icon/${image}`);
    },
  },
};
</script>

<style scoped>
.card-body {
  background-color: transparent !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.card-container {
  display: grid;
  grid-template-rows: 140px auto;
  border-radius: 0.4rem;
  width: 140px;
  height: 190px;
  gap: 0.7rem;
}
.card-container:hover {
  background-color: #576c30;
}
.card-container:hover .card-icon {
  transform: scale(1.2);
}
.card-link {
  text-decoration: none;
  color: inherit;
  display: contents;
}

.card-image {
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title {
  grid-row: 2;
  font-style: oblique;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: white;
  text-align: center;
  border-top: 2px solid white;
  padding-top: 0.3rem;
}

.card-icon {
  width: 30%;
  height: auto;
  cursor: pointer;
  transition: transform 0.6s, opacity 0.6s;
}
</style>

<style scoped>
@media (min-width: 577px) and (max-width: 868px) {
  .cards-container {
    gap: 0.8rem;
  }

  .card-container {
    display: grid;
    grid-template-rows: 100px auto;
    width: 100px;
    height: 150px;
    gap: 0.7rem;
  }

  .card-icon {
    width: 40%;
  }
  .card-title {
    padding-top: 0.5rem;
    font-size: 0.7rem;
  }
  .card-container:hover .card-icon {
    transform: scale(1.15);
  }
}
</style>
<style scoped>
@media (min-width: 1540px) {
  .cards-container {
    gap: 2.5rem;
  }

  .card-container {
    display: grid;
    grid-template-rows: 200px auto;
    width: 170px;
    height: 270px;
    gap: 1rem;
  }

  .card-container:hover .card-icon {
    transform: scale(1.2);
  }

  .card-title {
    padding-top: 0.6rem;
  }

  .card-icon {
    width: 45%;
  }
}
</style>
<style scoped>
@media (max-width: 576px) {
  .cards-container {
    gap: 0.8rem;
  }

  .card-container {
    display: grid;
    grid-template-rows: 80px auto;
    width: 80px;
    height: 130px;
    gap: 0.7rem;
  }

  .card-icon {
    width: 40%;
  }
  .card-title {
    padding-top: 0.5rem;
    font-size: 0.7rem;
  }
  .card-container:hover .card-icon {
    transform: scale(1.13);
  }
}
</style>
