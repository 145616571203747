<template>
  <nav
    class="navbar navbar-expand-lg navbar-light custom-navbar"
    :class="{ 'navbar-collapsed': isSidebarCollapsed }"
  >
    <div class="collapse navbar-collapse" id="navbarSupportedContent"></div>
    <div class="nav-icons">
      <div class="avatar-container">
        <img src="../assets/dev-durable.jpg" alt="Avatar" class="avatar" />
      </div>
    </div>
  </nav>
</template>

<script>
import { EventBus } from "@/eventBus";

export default {
  name: "HeaderProfil",
  data() {
    return {
      isSidebarCollapsed: false,
    };
  },
  created() {
    EventBus.on("sidebar-toggled", this.updateNavbar);
  },
  beforeUnmount() {
    EventBus.off("sidebar-toggled", this.updateNavbar);
  },
  methods: {
    updateNavbar(isCollapsed) {
      this.isSidebarCollapsed = isCollapsed;
    },
  },
};
</script>

<style scoped>
.custom-navbar {
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: rgba(4, 10, 20, 0.8);
  justify-content: space-between;
  font-family: Helvetica, Arial, sans-serif;
  transition: margin-left 0.5s;
}

.navbar-collapsed {
  margin-left: 65px;
  /* Width of collapsed sidebar */
}

.navbar-nav .nav-item {
  transition: background-color 0.3s ease;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.navbar-nav .nav-item:hover,
.router-link-active {
  background-color: rgba(220, 220, 220, 0.8);
}

.navbar-nav .nav-item .nav-link,
.nav-item a.nav-link {
  color: #ffffff;
  transition: color 0.3s ease;
}

.navbar-nav .nav-item:hover .nav-link {
  color: rgba(4, 10, 20, 0.8);
}

.router-link-active {
  color: rgba(4, 10, 20, 0.8) !important;
}

.navbar-logo {
  margin-left: 50px;
  margin-right: 10px;
  height: 27px;
}

.user-icon {
  color: white;
  margin-right: 5px;
}

.login-user {
  color: aliceblue;
  margin-right: 20px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.panier-icon {
  font-size: 18px;
  color: #ffffff;
  margin-top: 8px;
}

.nav-icons {
  display: flex;
  align-items: center;
  margin-right: 100px;
}

.panier-container {
  position: relative;
  display: inline-block;
}

.badge {
  position: absolute;
  top: -3px;
  margin-left: 1px;
  background-color: red;
  color: white;
  border-radius: 30%;
  padding: 2px 6px;
  font-size: 12px;
}

.login-link {
  text-decoration: none;
}

.avatar-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #fff;
}
</style>
